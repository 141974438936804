import * as React from 'react';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers';

const FingoSmallDatePicker = (props) => (
  <MobileDatePicker
    {...props}
    InputProps={{
      style: {
        padding: 5,
        fontSize: 12,
      },
    }}
    renderInput={(params) => (
      <TextField
        {...params}
        inputProps={{ ...params.inputProps, 'data-hj-allow': '' }}
        variant="standard"
      />
    )}
  />
);

export default FingoSmallDatePicker;
