import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import { ASSIGN_EXECUTIVE_TO_COMPANY, GET_DEBTORS } from '../../graphql';
import useGenericGetExecutives from '../../hooks/useGenericGetExecutive';
import { FilePropType } from '../../propTypes/FilePropType';
import { useGetCountryFromUrl } from '../../hooks';

const AssignNewExecutiveCell = ({ companyId, user, assignationType, ...extraProps }) => {
  const country = useGetCountryFromUrl().name;
  const [anchorEl, setAnchorEl] = useState(null);
  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const [assignExecutive] = useMutation(
    ASSIGN_EXECUTIVE_TO_COMPANY,
    {
      variables: {
        masterEntityId: companyId,
        assignationType,
      },
      onCompleted: closeMenu,
      refetchQueries: [GET_DEBTORS],
    },
  );
  const queryFilter = {
    ratification: { groups_Name: 'ratification', groups_Name_Iexact: country },
    collection: { groups_Name: 'collection', groups_Name_Iexact: country },
    executive: { executive_Active: true, groups_Name_Iexact: country },
  }[assignationType];
  const { executives: commercialExecutives } = useGenericGetExecutives(queryFilter);
  const openMenu = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const firstName = useMemo(() => user?.firstName || '', [user]);
  const picture = useMemo(() => user?.picture?.url || '', [user]);
  const executivesComponent = useMemo(() => (
    commercialExecutives.map((executive) => (
      <MenuItem
        key={executive.node.id}
        onClick={() => assignExecutive({ variables: {
          userId: executive.node.id,
        } })}
      >
        {executive.node.completeName}
      </MenuItem>
    ))
  ), [commercialExecutives]);
  return (
    <>
      <IconButton
        onClick={openMenu}
        disabled={false}
      >
        <Avatar
          alt={firstName}
          src={picture}
          {...extraProps}
        />
      </IconButton>
      <Menu
        keepMounted
        sx={{ display: 'inline', zIndex: 2000 }}
        anchorEl={anchorEl}
        onClose={closeMenu}
        open={Boolean(anchorEl)}
        id="documents-company-menu"
      >
        {executivesComponent}
      </Menu>
    </>
  );
};

AssignNewExecutiveCell.propTypes = {
  companyId: PropTypes.string,
  assignationType: PropTypes.oneOf(['ratification', 'collection', 'executive']).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    completeName: PropTypes.string.isRequired,
    picture: FilePropType,
  }),
};

AssignNewExecutiveCell.defaultProps = {
  companyId: null,
  user: {
    id: '0',
    firstName: 'Sin asignar',
    lastName: '',
    pitcure: null,
    completeName: 'Sin asignar',
  },
};

export default AssignNewExecutiveCell;
