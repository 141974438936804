import React, { useMemo } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { shoppingCartOffersVar } from '../../apollo/reactive-variables';
import { GridEditDateCell } from '.';
import { UNTIL_FIFTEEN_DAYS } from '../../constants';

const DateToPayEditCell = ({ params }) => {
  const currentOffers = useReactiveVar(shoppingCartOffersVar);
  const dateValue = useMemo(
    () => {
      const selectedOffer = currentOffers.find(({ invoiceId }) => params.id === invoiceId);
      return selectedOffer?.dateToPay || null;
    },
    [currentOffers],
  );

  const onChange = (row, newValue) => {
    shoppingCartOffersVar([
      { invoiceId: row.id, dateToPay: newValue },
      ...currentOffers.filter((offer) => offer.invoiceId !== row.id),
    ]);
  };

  const onChangeAll = (newValue) => {
    const updatedOffers = currentOffers.map(({ invoiceId }) => ({
      invoiceId,
      dateToPay: newValue,
    }));
    shoppingCartOffersVar(updatedOffers);
  };

  const momentDateExpiration = params.row?.dateExpiration
    ? moment(params.row?.dateExpiration)
    : null;

  const debtorAveragePaymentDays = useMemo(() => {
    const days = params.row?.receiver?.invoicesActualPaymentTerm;
    if (days === undefined) return null;
    if (days < 15) return null;
    return days;
  }, [params]);

  return (
    <GridEditDateCell
      {...params}
      debtorAveragePaymentDays={debtorAveragePaymentDays}
      value={dateValue}
      onChange={onChange}
      onChangeAll={onChangeAll}
      minDate={UNTIL_FIFTEEN_DAYS}
      maxDate={momentDateExpiration
        .clone()
        .add(params.row.offer?.fundingDaysAfterExpiration || 60, 'days')}
    />
  );
};

DateToPayEditCell.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    row: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      dateExpiration: PropTypes.objectOf(moment),
      offer: PropTypes.shape({
        fundingDaysAfterExpiration: PropTypes.number,
      }),
      receiver: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        invoicesActualPaymentTerm: PropTypes.number,
      }),
    }),
  }).isRequired,
};

export default DateToPayEditCell;
